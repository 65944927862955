import React from 'react'
import {HeroHeaderImage,Header,Footer,LeftImageRightTextAboutTop2,RecruitData,LeftImageRightTextAboutTop3,Map,CampanyData} from 'components';
const Recruit = () => {
  return (
    <>
      <Header/>
      <HeroHeaderImage>
      採用情報
      </HeroHeaderImage>
      <RecruitData/>
      
       <Footer/>
    </>
  )
}

export default Recruit
